import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBrowserWindow } from '../../../../utils/HelperUtils';
import './../../Cards/cards.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Pagination = ({ itemsPerPage, sitecoreContext, totalItems, currentPage, onPageChanged }) => {
  const pageNumbers = [];
  const [screenWidth, setScreenWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);
  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';
  const [visiblePages, setVisiblePages] = useState([]);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const canonical = sitecoreContext?.MetaData?.canonicalUrl;
  // Check if mobile screen
  useEffect(() => {
    let isMounted = true;
    let timeoutId;
    const windowBrowser = getBrowserWindow;

    setScreenWidth(windowBrowser && window?.innerWidth);

    function handleResize() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (isMounted) {
          setScreenWidth(windowBrowser && window?.innerWidth);
        }
      }, 100);
    }

    windowBrowser && window?.addEventListener('resize', handleResize);

    return () => {
      isMounted = false;
      windowBrowser && window?.removeEventListener('resize', handleResize);
    };
  }, []);
  // Screen width function
  const isMobileScreen = screenWidth < 992;

  const showPaginationPages = isMobileScreen ? 4 : 6;
  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };
  const fetchPageNumbers = (index, pageNeighbours, totalPages, setCurrentIndex) => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, index - pageNeighbours);
      const endPage = Math.min(totalPages - 1, index + pageNeighbours);
      setCurrentIndex(index);

      let noOfPages = range(startPage, endPage);
      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (noOfPages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          noOfPages = [LEFT_PAGE, ...extraPages, ...noOfPages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          noOfPages = [...noOfPages, ...extraPages, RIGHT_PAGE];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          noOfPages = [LEFT_PAGE, ...noOfPages, RIGHT_PAGE];
          break;
        }
      }
      return [1, ...noOfPages, totalPages];
    }

    return range(1, totalPages);
  };
  useEffect(() => {
    setCurrentIndex(currentPage);
    setVisiblePages(fetchPageNumbers(currentPage, 1, totalPages, setCurrentIndex));
  }, [currentPage, totalPages]);

  const goToPrev = (page) => {
    onPageChanged(page);
    setVisiblePages(fetchPageNumbers(page, 1, totalPages, setCurrentIndex));
  };
  const goToNext = (page) => {
    onPageChanged(page);
    setVisiblePages(fetchPageNumbers(page, 1, totalPages, setCurrentIndex));
  };
  const handleClick = (event, page) => {
    event.preventDefault();
    setCurrentIndex(page);
    onPageChanged(page);
    setVisiblePages(fetchPageNumbers(page, 1, totalPages, setCurrentIndex));
  };
  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonical + '?page=' + currentPage} />
      </Helmet>
      <nav
        className={['pagination-wrapper', isMobileScreen ? 'mobile' : ''].join(' ')}
        aria-label="Page navigation example"
      >
        <ul className="pagination">
          <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
            <a
              className={
                currentPage === 1
                  ? 'page-link paginationIcon IconPreviousDisabled'
                  : 'page-link paginationIcon IconPrevious'
              }
              href={location.pathname + '?page=' + (currentPage - 1)}
              onClick={(event) => {
                event.preventDefault();
                goToPrev(currentIndex - 1);
              }}
              aria-label="Previous"
            >
              <span aria-hidden="true">Previous</span>
            </a>
          </li>

          {visiblePages &&
            visiblePages?.map((number, vpindex) => {
              if (number === RIGHT_PAGE || number === LEFT_PAGE) {
                return (
                  <li key={vpindex} className="page-item" aria-label="Pagination">
                    <span aria-hidden="true">...</span>
                  </li>
                );
              }
              return (
                <li
                  key={vpindex}
                  className={
                    currentPage === number
                      ? 'page-number page-item active'
                      : 'page-number page-item'
                  }
                >
                  <a
                    className="page-link"
                    href={location.pathname + '?page=' + number}
                    onClick={(event) => {
                      event.preventDefault();
                      handleClick(event, number);
                    }}
                    aria-selected={currentIndex === number ? true : false}
                    aria-label={`${
                      currentIndex === number ? `Page ${currentIndex}` : `Go to page ${number}`
                    }`}
                  >
                    {number}
                  </a>
                </li>
              );
            })}

          <li
            className={
              currentPage === Math.ceil(totalItems / itemsPerPage)
                ? 'page-item disabled'
                : 'page-item'
            }
          >
            <a
              className={
                currentPage === Math.ceil(totalItems / itemsPerPage)
                  ? 'page-link paginationIcon IconNextDisabled'
                  : 'page-link paginationIcon IconNext'
              }
              href={location.pathname + '?page=' + (currentPage + 1)}
              onClick={(event) => {
                event.preventDefault();
                goToNext(currentIndex + 1);
              }}
              aria-label="Next"
            >
              <span aria-hidden="true">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  onPageChanged: PropTypes.func,
  currentPage: PropTypes.number,
};
export default withSitecoreContext()(Pagination);
