import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import PlayButton from '../../../static/images/play-button-polygon.svg';
import { withTranslation } from 'react-i18next';
const ManageContentTypeTags = ({ tags, t }) => {
  const [currCT, setCurrCT] = useState('');
  const [CTLabel, setCTLabel] = useState('');
  const [currentContentType, setCurrentContentType] = useState('');
  const [icon, setIcon] = useState('');
  const [cls, setCls] = useState('eventdata');
  const [isDisplay, setIsDisplay] = useState(false);
  const selectorCT = useSelector((state) => state?.masterReducer?.allcontenttypes);
  useEffect(() => {
    let cttemp = Array.isArray(tags?.CT) ? tags?.CT : [tags?.CT];
    let allCT = cttemp;
    const allCT55 = tags?.fields?.contentType
      ? Array.isArray(tags?.fields?.contentType)
        ? tags?.fields?.contentType
        : [tags?.fields?.contentType]
      : selectorCT;
    allCT = allCT?.id ? allCT : allCT55;
    const rawCT = tags?.CT;
    if (typeof rawCT === 'string') {
      rawCT ? setCls(`${rawCT?.replace(/\s/g, '')?.toLowerCase()}data`) : setCls('');
      setCurrCT(rawCT);
      setCurrentContentType(rawCT);
      let currCTLable = tags?.length;
      switch (rawCT) {
        case 'Event':
          currCTLable = tags?.text;
          break;
        case 'Article':
          currCTLable = tags?.length;
          break;
        case 'Webinar':
          currCTLable = tags?.length;
          setIsDisplay(true);
          break;
        case 'Video':
          currCTLable = tags?.length;
          setIsDisplay(true);
          break;
        default:
          currCTLable = tags?.length;
      }
      setCTLabel(currCTLable);
    } else {
      const ctCurr = Array.isArray(tags?.CT) ? tags?.CT[0] : tags?.CT;
      const ctCurrTag = typeof ctCurr === 'object' ? ctCurr?.id : ctCurr;
      let filtered = allCT && allCT?.filter((item) => item?.id === ctCurrTag);
      filtered = filtered && filtered?.length === 0 ? [ctCurr] : filtered;
      const curr =
        filtered && filtered[0]?.fields?.name
          ? filtered[0]?.fields?.name
          : filtered && filtered[0]?.name;
      const currIcon = filtered && filtered[0]?.fields?.icon?.value;
      curr ? setCls(`${curr?.replace(/\s/g, '')?.toLowerCase()}data`) : setCls('');
      setIcon(currIcon);
      setCurrCT(curr);
      setCurrentContentType(
        filtered && filtered[0]?.fields?.title
          ? filtered[0]?.fields?.title?.value
          : filtered && filtered[0]?.name
      );
      let currCTLable = curr;
      switch (curr) {
        case 'Event':
          currCTLable = tags?.text;
          break;
        case 'Article':
          currCTLable = tags?.mediaLength;
          break;
        case 'Webinar':
          currCTLable = tags?.mediaLength;
          setIsDisplay(true);
          break;
        case 'Podcast':
          currCTLable = tags?.mediaLength ? tags?.mediaLength : tags?.fields?.videoLength?.value;
          break;
        case 'Video':
          currCTLable = tags?.fields?.videoLength?.value
            ? tags?.fields?.videoLength?.value
            : tags?.mediaLength;
          setIsDisplay(true);
          break;
        default:
          currCTLable = tags?.mediaLength;
      }
      setCTLabel(currCTLable);
    }
  }, [tags?.CT]);
  return (
    <div className="cttagcontainer">
      <div>
        {CTLabel && (
          <>
            <span className={`cttagdata ${cls}`}>
              {isDisplay && <img className="ctplaydata" src={PlayButton} alt="play" />}
              {CTLabel}
            </span>
            <span className="bulletct">&bull;</span>
          </>
        )}
        <span className="txtct">{currentContentType}</span>
      </div>

      {currCT !== 'Event' && <div className="datect">{tags?.text}</div>}
    </div>
  );
};

ManageContentTypeTags.defaultProps = {
  cssClass: '',
  contentType: '',
  date: '',
  tags: [],
};

ManageContentTypeTags.propTypes = {
  cssClass: PropTypes.string,
  contentType: PropTypes.string,
  date: PropTypes.string,
  tags: PropTypes.array,
};

export default withTranslation()(ManageContentTypeTags);
