import {
  GraphQLLayoutService,
  RestLayoutService,
  constants,
} from '@sitecore-jss/sitecore-jss-react';
import Cookies from 'js-cookie';
import config from '../temp/config';
import { isEmpty } from 'lodash';
import { getBrowserWindow, isSCExperienceEditorActive } from '../utils/HelperUtils';
let globalInsightData;
if (!isSCExperienceEditorActive) {
  if (getBrowserWindow) {
    globalInsightData = location.pathname.includes('/insights/')
      ? Cookies.get('isGlobalToCountryInsight')
      : Cookies.remove('isGlobalToCountryInsight');
  }
}
export class LayoutServiceFactory {
  create() {
    return process.env.REACT_APP_FETCH_WITH === constants.FETCH_WITH.GRAPHQL
      ? new GraphQLLayoutService({
          endpoint: config.graphQLEndpoint,
          apiKey: config.sitecoreApiKey,
          siteName: config.jssAppName,
        })
      : new RestLayoutService({
          apiHost: config.sitecoreApiHost,
          apiKey: config.sitecoreApiKey,
          siteName: config.jssAppName,
          configurationName: 'jss',
        });
  }
}

export const layoutServiceFactory = new LayoutServiceFactory();
